import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';

import { useI18n } from 'services/i18n';
import { Container, Row, Col } from 'styles/grid';
import media from 'styles/media';
import colors from 'styles/colors';
import { SecondaryButton } from './buttons';
import { SecondaryTitle, BlockTitle, BiggerText, Text } from './texts';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.lightBlueGrey};
  border-radius: 6px;
  width: 100%;
  padding: 0 20px;
  margin-top: 40px;
  ${media.md`
    padding: 20px 40px;
  `}
`;

const CardLink = ({ t, link }) => (
  <Card>
    <BiggerText weight="bold" style={{ marginBottom: 10 }}>
      {t(`links.${link}Title`)}
    </BiggerText>
    <Text style={{ marginTop: 0, marginBottom: 40 }}>{t(`links.${link}Text`)}</Text>
    <SecondaryButton to={'/' + link} text={t(`links.${link}Link`)} />
  </Card>
);

const Links = ({ withTitle, withBlockTitle, links }) => {
  const [t] = useI18n();
  return (
    <section>
      <Container>
        {withTitle && (
          <Row>
            <Col>
              <SecondaryTitle>{t('links.title')}</SecondaryTitle>
            </Col>
          </Row>
        )}
        <Row justify="space-around">
          {links.map((link, i) => (
            <Col key={i} sm={12} md={5}>
              {withBlockTitle && <BlockTitle>{t(`links.${link}BlockTitle`)}</BlockTitle>}
              <CardLink t={t} link={link} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

Links.propTypes = {
  withTitle: Proptypes.bool,
  withBlockTitle: Proptypes.bool,
  links: Proptypes.array.isRequired,
};

export default Links;
