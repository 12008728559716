import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby-plugin-intl';
import {useStaticQuery, graphql} from 'gatsby';

import {useI18n} from 'services/i18n';
import colors from 'styles/colors';
import {Container, Row, Col} from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import NonStretchedImage from 'components/non-stretched-image.js';
import Links from 'components/links.js';
import {PrimaryTitle, BlockTitle, ParagraphTitle} from 'components/texts';

const StyledArticle = styled.article`
  margin: 6.25rem 0;
`;

const BlogLink = styled(Link)`
  color: ${colors.black};
  :hover {
    color: ${colors.fitle};
  }
`;

const Article = ({article, postIdToImg}) => (
    <StyledArticle>
        <Container>
            <Row justify="space-between">
                <Col sm={12} md={6}>
                    <Row>
                        <BlockTitle style={{marginBottom: 0, textDecoration: 'none'}}>
                            <BlogLink style={{marginBottom: 0, textDecoration: 'none'}} to={`/article/${article.node.post_name}`}>
                                {article.node.post_title}<br/>
                            </BlogLink>
                            <ParagraphTitle>{new Date(article.node.post_date).toLocaleDateString()}</ParagraphTitle>
                        </BlockTitle>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <div
                            dangerouslySetInnerHTML={{ __html: article.node.post_excerpt }}
                        />

                    </Row>
                </Col>
                <Col sm={12} md={6} style={{margin: 'auto', display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
                    {article.node.image && (
                        <Col sm={12} md={5}>
                            <NonStretchedImage fixed={article.node.guid} alt={(`blog.${article}.imageAlt`)}/>
                        </Col>
                    )}
                    {postIdToImg[article.node.ID] && (<img src={postIdToImg[article.node.ID]} alt={article.node.post_title} width="320" height="200" style={{objectFit: "cover"}} loading="lazy"/>)}
                </Col>
            </Row>
            <Row justify="space-between">
            </Row>
        </Container>
    </StyledArticle>
);
//SELECT p.*, m.meta_value, pp.guid from  wp_posts p
//LEFT JOIN wp_postmeta m ON (m.post_id=p.ID AND m.meta_key='_thumbnail_id')
//LEFT JOIN wp_posts pp ON pp.ID=m.meta_value
const Blog = () => {
    const [t, language] = useI18n();
    const data = useStaticQuery(graphql`
    query {
      articles: allMysqlPostTitle(filter: {post_type: {eq: "post"}, post_status: {eq: "publish"}}, sort: {fields: id, order: DESC}) {
        edges {
          node {
            ID
            post_title
            post_name
            post_author
            post_content
            post_excerpt
            post_date
            guid
          }
        }
      }
      meta_data: allMysqlPostMeta {
        nodes {
          meta_id
          meta_key
          meta_value
          post_id
        }
      }
      sizing_chart_readability_improved: file(relativePath: { eq: "blog/userposition.png" }) {
        childImageSharp {
          fixed(width: 320, height: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      user_voice_sessions: file(relativePath: { eq: "blog/uservoice.png" }) {
        childImageSharp {
          fixed(width: 320, height: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

    const articlesToCheck = [];

    data.articles.edges.forEach((article, _) => (
        articlesToCheck.push({article})
    ))

    const postIdToDescriptionImage = {}
    for (let i =0; i<data.meta_data.nodes.length; i++) {
      if (data.meta_data.nodes[i].meta_key==='image_description') {
        postIdToDescriptionImage[data.meta_data.nodes[i].post_id] = data.meta_data.nodes[i].meta_value
      }
    }
  
    return (
        <Layout>
            <SEO title={t('seo.title.blog')} lang={language} description={t('seo.description.blog')}/>
            <section>
                <Container>
                    <Row justify="flex-start">
                        <Col>
                            <PrimaryTitle>{t('blog.title')}</PrimaryTitle>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                {data.articles.edges.map((article, i) => (
                    <Article
                        key={i}
                        article={article}
                        postIdToImg={postIdToDescriptionImage}
                    />
                ))}
            </section>
            <Links withTitle={true} links={['plugin', 'dashboard']}/>
        </Layout>
    );
};

export default Blog;
